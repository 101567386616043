import React from 'react';
import { Link } from 'gatsby';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styled from 'styled-components';
import SEO from '../../components/seo';
import Layout from '../../components/Layout';
import thumb from '../../images/walking.webp';

const Place = styled.h2`
  font-size: 40px;
  position: absolute;
  z-index: 1000;
`;

const Events = styled.section`
  display: flex;
  flex-wrap: wrap;
  .event-container__event {
    position: relative;
  }
`;

function OplevelserPage() {
  return (
    <>
      <SEO title="Læs mere om hvilke steder der er værd at besøge i Danmark" />
      <Layout withoutSVG isBlog>
        <section className="event-container">
          <Events>
            <Link
              className="event-container__event x2y1"
              to="/oplevelser/aalborg-karneval"
            >
              <div className="event-content">
                <LazyLoadImage
                  threshold={360 * 2}
                  src="https://www.aalborgkarneval.dk/wp-content/uploads/2018/12/Vesterbro.jpg"
                  alt="Aalborg Karneval"
                />
                <Place>Aalborg Karneval</Place>
              </div>
            </Link>
            <Link
              className="event-container__event x2y1"
              to="/oplevelser/comedy-ballroom"
            >
              <div className="event-content">
                <LazyLoadImage
                  threshold={360 * 2}
                  src="http://cdn.tix.dk/tix/EventImages/Event_12921.jpg"
                  alt="Comedy Ballroom"
                />
                <Place>Comedy Ballroom</Place>
              </div>
            </Link>

            <Link
              className="event-container__event x2y1"
              to="/oplevelser/events-i-kobenhavn"
            >
              <div className="event-content">
                <LazyLoadImage
                  threshold={360 * 2}
                  src="/images/eventscopenhagen.webp"
                  alt="Hi"
                />
                <Place>Events i København</Place>
              </div>
            </Link>
            <Link
              className="event-container__event x2y1"
              to="/oplevelser/brian-er-en-nar"
            >
              <div className="event-content">
                <LazyLoadImage
                  threshold={360 * 2}
                  src="https://www.billetlugen.dk/obj/media/DK-eventim/teaser/222x222/2021/brian_er_en_nar_222x222.jpg"
                  alt="Hi"
                />
                <Place>Brian Mørk - Brian er en nar!</Place>
              </div>
            </Link>

            <Link
              className="event-container__event x2y1"
              to="/oplevelser/vandreture-i-danmark"
            >
              <div className="event-content">
                <LazyLoadImage threshold={360 * 2} src={thumb} alt="Hi" />
                <Place>Vandretur i Danmark</Place>
              </div>
            </Link>

            <Link
              className="event-container__event x2y1"
              to="/oplevelser/gin-tonic-festival-i-aarhus"
            >
              <div className="event-content">
                <LazyLoadImage
                  threshold={360 * 2}
                  src="https://www.billetlugen.dk/obj/media/DK-eventim/teaser/222x222/2019/gin&tonic222x222.jpg"
                  alt="Hi"
                />
                <Place>Gin & Tonic festival i Aarhus</Place>
              </div>
            </Link>

            <Link
              className="event-container__event x2y1"
              to="/oplevelser/helsingør-vinfestival"
            >
              <div className="event-content">
                <LazyLoadImage
                  threshold={360 * 2}
                  src="https://vinfestival.dk/media/9550/helsingoervinfest_plakat22FINAL_500x700.jpg"
                  alt="Hi"
                />
                <Place>Oplev Helsingør vinfestival</Place>
              </div>
            </Link>
          </Events>
        </section>
        <div className="explore-container">
          <h1>Anbefal steder at opleve på Zooticket</h1>
          <p>
            Har du lyst til at skrive om et sted, en festival eller andre
            events, som man bør opleve?
            <br />
            Kontakt os på{' '}
            <a
              style={{ textDecoration: 'underline' }}
              href="mailto:jk@midear.dk"
            >
              mail her
            </a>
          </p>
        </div>
      </Layout>
    </>
  );
}

export default OplevelserPage;
